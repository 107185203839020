<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/transparency/banner.jpg') + ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-white text-3xl font-bold">Transparencia</h2>
        <p class="text-gray-100">Home / Transparencia</p>
      </div>
    </div>

    <!-- SECTION TRANSPARENCIA -->
    <section class="py-10">
      <!-- TABLE -->
      <div
        class="container mx-auto w-full md:w-3/4 p-4 md:p-10 bg-white rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="bg-red-700 uppercase text-white rounded text-xl p-1 mb-6">
          <div class="flex justify-between mx-6 md:mx-20">
            <span>Título</span>
            <span>Ver</span>
          </div>
        </div>
        <!-- LIST 1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              Revista CREDI YA 2023 Segunda Edición Aniversario 12 años.
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1IrmXFJ2LcIjeH4aJsOXkI4XQer63Ltka/view?usp=sharinga"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 1.1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              ESTATUTOS COOPERATIVA DE AHORRO Y CREDITO CREDI YA LTDA.
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1s3rDXGm9JWLBBU7Eoh-IrUutPGN9B2XT/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 1 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              BALANCE GENERAL 2022
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/11KeGQG-Z8lSdfw6Xq0QNZ-x9uLrdFAz0/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>

        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              ESTADO DE RESULTADOS 2022
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1tEX9mAbnzSFBtycuVKXJqqThP4Qrvh9p/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>

        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">COSTOS FINANCIEROS</h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/INFORMATIVOCREDIYA23012025.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">PERMISOS DE FUNCIONAMIENTO</h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/PermisosdeFuncionamientoAgencias.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <!-- LIST 2 -->
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO ACCESO A PRODUCTOS DE CRÉDITO
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1-y93HVCpK-iBSDqcgKef_IsP32jf2131/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO EDUCACIÓN FINANCIERA
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/13UeZzgNc8LpXDNDxnvrVdoURVzg8vISh/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO REPRESENTACIÓN
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1CP5z6ZoGGr2OanM8Sw9DNYzv30KzgIqF/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO DE LA GERENCIA Y JEFATURAS DE ÁREAS
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1W4IhCa1_RGnOf2QJoKK6JWnJXDQT7TUj/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO DE LOS RECLAMOS PRESENTADOS POR LOS
              USUARIOS
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/17zgiN4YB-iSfGVt21nAlZUPFKXfpBW98/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO SOCIOS
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1L_GoZdyAFpq4sQDHY_V8dLRFhZZPigx-/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO DE LOS CONSEJOS DE ADMINISTRACIÓN Y
              VIGILANCIA
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1yVZPpUuCc0f3WpHyXahDxL8IzQtZ_cD5/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO DE LA ASAMBLEA O JUNTA GENERAL
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/1B4xoLFjJ4Qtm0Uj5ko9vkFSUYqYcWeCa/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GOBIERNO DEPÓSITOS POR GÉNERO MARZO 2022
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/16OTlWWPWmqwZjFMw4N4Xc5zlc-vlBQsV/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              INDICADORES DE GENERO
            </h5>
          </div>
          <div class="right">
            <a
              href="https://drive.google.com/file/d/18-dQ9DnkGcpAPybJFSv9tVAxgpwYkOtW/view?usp=sharing"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "WorkWithUs"
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
